<!-- 会员充值记录-->
<template>
    <div>
        <ctrl-box class="ctrl-box" :data="{}">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="showQuery = !showQuery">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-search" font-size="27px;"/>
                            <span class="txt" >{{msg('搜索')}}</span>   
                        </div>
                    </el-link>
                    <el-link :underline="false" @click="goVip()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-chongzhihuiyuan" font-size="27px;"/>
                            <span class="txt" >{{msg('去充值')}}</span>   
                        </div>
                    </el-link>
                    <el-link :underline="false" @click="queryPayResult()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-kuaishuchajian" font-size="27px;"/>
                            <span class="txt" >{{msg('查询支付结果')}}</span>   
                        </div>
                    </el-link>
                </div>
                <div class="xgroup">
                    <el-link :underline="false" @click="doExport()">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-daochu2" font-size="27px;"/>
                            <span class="txt">{{msg('导出')}}</span>
                        </div>            
                    </el-link>
                    <el-link :underline="false" @click="doDelete()" v-if="hasPermission('vip:delete')">
                        <div class="wrap">
                            <icon-svg icon-class="icon-shanchu1" font-size="27px;"/>
                            <span class="txt" >{{msg('删除')}}</span>   
                        </div>
                    </el-link>
                    <el-link style="margin-left:30px;" :underline="false" @click="queryPayResultFree()" v-if="hasPermission('vip:free')">
                        <div class="wrap" >
                            <!-- <i class="iconfont icon-sousuo font22" ></i> -->
                            <!-- <span class="txt" >{{"已付款"}}</span>    -->
                        </div>
                    </el-link>
                </div>
            </template>
        </ctrl-box>
 
        <!--查询区域-->
        <transition name="el-zoom-in-top">
            <query-box :query="query" v-show="showQuery" labelWidth="80px">
                <template v-slot:default="scope">
                    <el-form-item v-if="admin === 1" :label="msg('姓名 账号')">
                        <!-- <el-input v-model="scope.query.name"  :placeholder="msg('账号或者姓名')" clearable></el-input> -->
                        <el-input type="textarea"  :rows="3" v-model="scope.query.names" style="margin-top:0px;">
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="msg('流水号')">
                        <!-- <el-input v-model="scope.query.code" clearable></el-input> -->
                        <el-input type="textarea"  :rows="3" v-model="scope.query.orderCode" style="margin-top:0px;">
                        </el-input>
                    </el-form-item>
                    <el-form-item :label="msg('支付状态')">
                        <el-select v-model="scope.query.status" clearable>
                            <el-option
                                v-for="item in dicts.vipOrderStatus"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="msg('创建时间')">
                        <el-date-picker
                            v-model="scope.query.Date"
                            type="datetimerange"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            clearable
                            :range-separator="msg('至')"
                            :start-placeholder="msg('开始日期')"
                            :end-placeholder="msg('结束日期')">
                        </el-date-picker>
                    </el-form-item>
                    <el-row>
                        <el-button type="primary" icon="el-icon-search" @click="getOrders" size="mini">{{msg('搜索')}}</el-button>
                        <el-button icon="el-icon-refresh" size="mini" @click="reset" >{{msg('重置')}}</el-button>
                    </el-row>
                </template>
            </query-box>
        </transition>

        <div class="table-wrap">
            <vxe-grid ref="dataTable" :height="docHeight" size="mini"
                resizable border export-config
                show-overflow highlight-hover-row
                highlight-current-row highlight-hover-column highlight-current-column
                :data="orders"
                :columns="columns"
                @cell-click="checkRow"
            ></vxe-grid>

            <pagenator
                @page-change="handlerPageChange"
                :query="query"
            >
            </pagenator>
        </div>
    </div>
   
</template>

<script>
/* eslint-disable */　
import CtrlBox from "../../components/CtrlBox.vue";
import QueryBox from "../../components/QueryBox.vue";
import Pagenator from "../../components/Pagination.vue";
export default {
    name: 'VipOrder',
    components: {
        CtrlBox: CtrlBox,
        QueryBox: QueryBox,
        Pagenator:Pagenator,
    },
    mounted(){
        this.loginUserId = this.$store.state.loginId;
        this.admin = this.$store.state.admin,
        this.buildColumns();
        this.getOrders();
    },
    props: {
        userId: Number,
    },
    data(){
        return {
            query:{
                orderCode:null,
                names:null,
                status: null,
                Date: [],
                length: 20, //每页显示的长度
                start: 0, //起始数量
                pageTotal:0,//总记录数
                pageIndex: 1,
            },
            admin: null,
            loginUserId: null,

            showQuery: false,
            columns: [
                
            ],
            orders: [],      //流水列表
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods:{
        buildColumns(){
            this.columns = [
                { type: 'checkbox', minWidth: 30, sortable:true },
                {field: "id", title: this.msg("ID"), minWidth: 40, sortable:true},
                {field: "created", title: this.msg("创建时间"), minWidth: 120, sortable:true},
                {field: "status", title: this.msg("状态"), minWidth: 60, sortable:true, 
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                    slots: {
                        default: ({ row }) => {
                            let status = this.dicts.vipOrderStatus.find(d => d.value === row.status);
                            return [
                                <el-tag type={status?status.type:''}>{status?status.label:row.status}</el-tag>
                            ];
                        },
                    }
                },
                {field: "amount", title: this.msg("金额"), minWidth: 50, sortable:true,
                    slots: {
                        default: ({ row }) => {
                            let amount = row.amount/100.0;
                            amount = amount.toFixed(2);
                            return amount;
                        }
                    }
                },
                {field: "username", title: this.msg("账号"), minWidth: 120, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "fullname", title: this.msg("姓名"), minWidth: 120, sortable:true,
                    filters:[{data: {vals: [], sVal: ''}}],
                    filterRender:{name: 'FilterContent'},
                },
                {field: "code", title: this.msg("流水号"), minWidth: 120, sortable:true},
                {field: "shopQty", title: this.msg("店铺数"), minWidth: 60, sortable:true,
                },
                {field: "remark", title: this.msg("说明"), minWidth: 300, sortable:true},
            ]
        },
        getOrders(refresh){ 

            if(refresh){
                this.query.pageIndex = 1;
                this.query.start = 0;
            }

            var params = this.$kit.rebuildQuery(this.query);

            if(params.names){
                let cs = this.query.names.split(/[\s,]+/);
        
                let css = [];
                for(let i=0; i<cs.length; i++){
                    if(cs[i]) css.push(cs[i]);
                }
                params.names = css.join(",");
            }
            if(params.orderCode){
                let cs = this.query.orderCode.split(/[\s,]+/);
        
                let css = [];
                for(let i=0; i<cs.length; i++){
                    if(cs[i]) css.push(cs[i]);
                }
                params.orderCode = css.join(",");
            }
            this.$axios.post(this.$kit.api.vip.orders, params)
                .then((result) => {
                    if(result.status){
                        this.orders = result.data.list;
                        this.query.pageTotal = result.data.total;
                    }
                });
        },
        reset(){
            this.query.names = null;
            this.query.orderCode = null;
            this.query.status = null;
            this.query.Date = [];
            this.query.start = 0;
        },
        //点击行，选中行
        checkRow({row, $event}){
            //如果点击checkbox 则不处理
            if($event.target.className.indexOf("vxe-checkbox--icon")== -1){
                var table =  this.$refs.dataTable;
                table.setCheckboxRow([row], !table.isCheckedByCheckboxRow(row))
            }
        },
        //分页数据发生改变，回调事件：info[0]: 每页显示的数量，info[1]: 起始页
        handlerPageChange(info){
            this.query.length = info[0];
            this.query.start = info[1];
            this.getOrders();
        },
        queryPayResult(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0 || selectes.length>1){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            this.$axios.get(this.$kit.api.vip.query+"?id="+selectes[0].id)
                .then((result) => {
                    if(result.status){

                        let status = result.data.status;
                        let statusObj = this.dicts.vipOrderStatus.find(s => s.value == status);
                        if(statusObj){
                            this.$message(statusObj.label);
                        }

                        if(status>0){
                            if(this.admin == 1){
                                this.getOrders();
                            }else{
                                //重载页面刷新权限
                                window.location.href=window.location.href+"?refresh=true";
                            }
                        }
                    }else{
                        this.$message(result.msg);
                    }
                });

        },
        queryPayResultFree(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0 || selectes.length>1){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            this.$confirm(this.msg('确认该订单已付款?'), '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.get(this.$kit.api.vip.queryFree+"?id="+selectes[0].id)
                .then((result) => {
                    if(result.status){

                        let status = result.data.status;
                        let statusObj = this.dicts.vipOrderStatus.find(s => s.value == status);
                        if(statusObj){
                            this.$message(statusObj.label);
                        }

                        if(status>0){
                            if(this.admin == 1){
                                this.getOrders();
                            }else{
                                //重载页面刷新权限
                                window.location.href=window.location.href+"?refresh=true";
                            }
                        }
                    }else{
                        this.$message(result.msg);
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消操作'
                });          
            });
        },
        doDelete(){
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length==0 || selectes.length>1){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            this.$confirm(this.msg('确定要删除?'), '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$axios.get(this.$kit.api.vip.deleted+"?id="+selectes[0].id)
                .then((result) => {
                    this.$message(result.msg);
                    if(result.status){
                        this.getOrders();
                    }                    
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消操作'
                });          
            });
        },
        goVip(){
            this.$tabs.open("/admin/vip");
        },
        doExport(){
            this.$refs.dataTable.openExport({ isPrint: false })
        },
    }
    
}
</script>

<style scoped lang="scss">

</style>
