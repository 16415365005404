<template>

    <div class="vip-wrap">
        <div class="table-wrap">
            <table class="var-table" cellspacing="0" cellpadding="0">
                <thead>
                    <tr>
                        <th class="title-vip-type" rowspan="2"><span>{{msg('会员类型')}}</span></th>
                        <th class="title-group" :colspan="licenseLength"><span>{{msg("可拥有账号个数")}}</span></th>
                    </tr>
                    <tr class="title-license">
                        <th v-for="(remark, idx) in remarks" :key="idx"><span>{{remark}}</span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(value, key) in vips" :key="key">
                        <td v-if="vipBuyType.indexOf(key)>-1" class="cell-vip">
                            <span>{{vipLabels[key]}}
                                <br><span v-show="key!='vip6'&&key!='vip7'&&key!='vip9'" style="font-size:15px;">{{msg('赠送可绑店铺数：')}}<span style="font-size:20px;font-weight:bold">{{shopLabels[key]}}</span>{{' 家'}}</span>
                            </span>
                        </td>
                        <td v-for="(val, idx) in value" :class="['cell-price', (currVipKey===key && currVipIdx==idx)? 'active':'']" @click="checkVip(val, key, idx)" :key="idx">
                            <span v-if="vipBuyType.indexOf(key)>-1">{{(val.price/100).toFixed(2)}}<span style="font-size:18px;">元/月</span></span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-if="vipBuyType.indexOf('vip1')<0&&vipBuyType.indexOf('vip2')<0&&vipBuyType.indexOf('vip3')<0" style="text-align:center; margin-top: 10px;margin-bottom: 10px; padding:10px;font-size:16px;color:red;" class="vip-auth">
            <el-checkbox-group v-model="saleIds">
                <el-checkbox v-model="saleIds" @change="(val)=>handerSaleChange(val, sale)"  
                    v-for="sale in sales" style="margin:10px;" :label="sale.id" :key="sale.id" 
                    border size="medium"
                    :disabled="!currVipKey"
                >{{sale.remark}}</el-checkbox>
            </el-checkbox-group>
        </div>

        <div style="text-align:center;margin:10px;">
            <div style="margin:10px;color:#F56C6C;font-weight:bold">
                <span>{{msg('点此处可增加绑定店铺数量：')}}</span><el-switch v-model="buyShop" :disabled="buyShopDisabled" active-color="#13ce66" inactive-color="#ff4949" @change="buyShopChange"></el-switch>
                <div style="margin:10px;"><span>{{msg('升级账号绑定店铺数至：')}}</span><el-input-number size="mini" v-model="shopQtyLevel" :disabled="!showShopQty" :step="1" :min="1" :max="100" @change="handerShopQtyChange" @blur="handerShopQtyBlur" :step-strictly="true"></el-input-number><span> {{msg('级(每提升一级可多绑定10家店铺)')}}</span></div>
            </div>
            <el-button type="danger" round @click="prepareBuy">
            <i class="el-icon-shopping-cart-full" style="font-size:20px;"></i>
            <span style="display:inline-block; margin-left:5px;" v-if="vipTxt">{{vipTxt}}</span>
            <span style="display:inline-block; margin-left:5px;" v-if="qtyTxt">{{qtyTxt}}</span>
            <span style="display:inline-block; margin-left:5px;" v-if="shopQtyTxt">{{shopQtyTxt}}</span>
            <span style="display:inline-block; margin-left:5px;" v-if="saleTxt">{{saleTxt}}</span>
            <span style="display:inline-block; margin-left:10px; font-size:20px" >{{(amount * currMonths * currSale).toFixed(2)}}<span>￥</span></span>
            </el-button>
        </div>

        <div class="vip-auth" style="font-size:18px;color: #f30c0c;">
            <span>温馨提示：续费后如会员没有生效，可点击左上方【续费】旁的帮助图标，再按其指引方法操作一下即可；</span>
        </div>

        <div class="vip-auth" v-if="remark1Txt">
            <span v-html="remark1Txt"></span>
            <span v-html="remark2Txt"></span>
            <span v-html="remark3Txt"></span>
        </div>

        <div class="vip-auth" style="font-size:18px;color: #f30c0c;">
            <span>请注意：套餐可根据实际需要随时调整，调整套餐时，套餐内的剩余金额补偿到新套餐内后如还有剩余金额，系统将会自动清零；请知悉！</span>
            <span>举例：如您续费了会员账号个数为2-10个，100元每月的高级会员套餐一个季度，费用为300元；使用一个月后账号内的剩余会费为：
            200元；这时如又想换会员账号个数为2-10个150元每月的超级会员，如这时选择按季度续费，所需费用为150X3=450元；因账号本身还剩有200元会费，这时只需补450-200=250元的差价；如选择按月续费，所需费用为150X1=150元；因账号本身剩余还剩有200元会费，这时无需补差价；但多出来的50元，系统将会自动清零，不会再加入到下个套餐的续费。</span>
        </div>

    </div>

</template>

<script>
export default {
    name: 'role',
    components: {},
    mounted:function(){
        this.getVipSet();
        this.list();
        this.getShopQtyNum();
    },
    data(){
        return{
            licenseLength: 0,
            remarks: [],
            prices: [],
            vips: {},
            sales: [],
            vipLabels: {},
            shopLabels:{},
            saleIds: [],
            vipTxt: "普通会员",
            qtyTxt: "1-10用户",
            shopQtyTxt:"3家",
            saleTxt: "",
            amount: 50,
            shopQty:20,
            shopQtyLevel:1,
            showShopQty:false,
            buyShop:false,
            buyShopDisabled:false,
            
            currMonths: 1,
            currSale: 1,

            currVipKey: null,
            currVipIdx: null,
            currVip: null,

            remark1Txt:null,
            remark2Txt:null,
            remark3Txt:null,

            vipBuyType:null,
        }
    },
    methods: {
        getVipSet(){
            this.$axios.get(this.$kit.api.user.getVipSet)
                .then((result)=>{
                    if(result.status){
                        var u = result.data;
                        this.vipBuyType = u.vipBuyType;
                    }
                });
        },
        list(){
            this.$axios.get(this.$kit.api.vip.prices)
                .then((result)=>{
                    if(result.status){
                        let data = result.data;
                        this.prices = data.vips;
                        this.sales = data.sales;
                        let vs = {}
                        this.prices.forEach(p => {

                            let vip = vs[p.vip];
                            if(!vip){
                                vip = [];
                                vs[p.vip] = vip;
                            }

                            this.vipLabels[p.vip]=p.vipLabel;
                            vip.push(p);
                           
                        });
                        this.vips = vs;
                        this.remarks = this.vips["vip1"].map(e => e.remark1);
                        this.licenseLength = this.vips["vip1"].length;
                    }
                });
        },
        getShopQtyNum(){
            this.$axios.get(this.$kit.api.shop.getShopQtyNum)
                .then((result)=>{
                    if(result.status){
                        let data = result.data;
                        this.shopLabels = {
                            'vip1':data.vip1,
                            'vip2':data.vip2,
                            'vip3':data.vip3,
                            'vip5':data.vip5,
                            'vip6':data.vip3,
                            'vip7':data.vip7,
                            'vip9':data.vip9,
                        }
                    }
                });
        },
        handerSaleChange(val, sale){
            if(val){
                this.saleIds = [sale.id];
                this.saleTxt = "折扣："+sale.sale+"%";
                this.currMonths = sale.months;
                this.currSale = (sale.sale / 100.0).toFixed(2);
            }else if(this.saleIds.length==0){
                this.saleTxt = "";
                this.currMonths=1;
                this.currSale=1;
            }
        
        },
        buyShopChange(value){
            if(this.currVip == null){
                this.$message(this.msg('请选择要续费的会员类型'));
                this.buyShop = false;
                return;
            }
            let defaultQty = this.shopLabels[this.currVipKey];
            if(value){
                this.showShopQty = true;
                let qty = 0;
                if(this.currVipKey == 'vip1'){
                    this.shopQty = 10;
                    this.shopQtyLevel = 1;
                    qty = this.shopQty-defaultQty;
                    this.amount = (this.price/100.0+(qty*3)).toFixed(2);
                    this.shopQtyTxt = "可绑店铺数："+this.shopQty+"家";
                }else if(this.currVipKey == 'vip2'){
                    this.shopQty = 10;
                    this.shopQtyLevel = 1;
                    qty = this.shopQty-defaultQty;
                    this.amount = (this.price/100.0+(qty*3)).toFixed(2);
                    this.shopQtyTxt = "可绑店铺数："+this.shopQty+"家";
                }else if(this.currVipKey == 'vip3' || this.currVipKey == 'vip6' || this.currVipKey == 'vip9'){
                    this.shopQty = defaultQty;
                    this.shopQtyLevel = 2;
                    qty = this.shopQty-defaultQty;
                    this.amount = (this.price/100.0+(qty*3)).toFixed(2);
                    this.shopQtyTxt = "可绑店铺数："+this.shopQty+"家";
                }else if(this.currVipKey == 'vip5'){
                    this.shopQty = 10;
                    this.shopQtyLevel = 1;
                    qty = this.shopQty-defaultQty;
                    this.amount = (this.price/100.0+(qty*3)).toFixed(2);
                    this.shopQtyTxt = "可绑店铺数："+this.shopQty+"家";
                }
            }else{
                this.showShopQty = false;
                this.shopQtyLevel = 1;
                this.shopQty = defaultQty;
                this.amount = (this.price/100.0).toFixed(2);
                this.shopQtyTxt = "可绑店铺数："+this.shopQty+"家";
            }
        },
        checkVip(val, key, idx){
            // console.log(val, key, idx);

            // this.shopQty = this.shopLabels[key];
            if(key == 'vip7'){
                this.buyShopDisabled = true;
            }else{
                this.buyShopDisabled = false;
            }

            let qty = 0;
            // if(key == 'vip3'){
            //     this.showShopQty = true;
            //     qty = (this.shopQty-20)/10;
            // }else{
            //     this.showShopQty = false;
            //     this.shopQty = this.shopLabels[key];
            //     qty = 0;
            // }

            let defaultQty = this.shopLabels[key];
            if(this.buyShop){
                if(key == 'vip1'){
                    this.shopQty = 10;
                    this.shopQtyLevel = 1;
                    qty = this.shopQty-defaultQty;
                }else if(key == 'vip2'){
                    this.shopQty = 10;
                    this.shopQtyLevel = 1;
                    qty = this.shopQty-defaultQty;
                }else if(key == 'vip5'){
                    this.shopQty = 10;
                    this.shopQtyLevel = 1;
                    qty = this.shopQty-defaultQty;
                }else if(key == 'vip3' || key == 'vip6' || key == 'vip9'){
                    this.shopQty = defaultQty;
                    this.shopQtyLevel = 2;
                    qty = this.shopQty-defaultQty;
                }
            }else{
                this.shopQty = defaultQty;
            }

            this.currVipKey = key;
            this.currVip=val;
            this.currVipIdx = idx;

            this.vipTxt = val.vipLabel;
            if(key == 'vip7'){
                this.qtyTxt = "账号数量无限制";
                this.shopQtyTxt = "电商店铺数无限制";
            }else{
                this.qtyTxt = val.remark1;
                this.shopQtyTxt = "可绑店铺数："+this.shopQty+"家";
            }

            this.amount = (val.price/100.0+(qty*3)).toFixed(2);
            this.remark1Txt = val.typeRemark1;
            this.remark2Txt = val.typeRemark2;
            this.remark3Txt = val.typeRemark3;
            this.price = val.price;
            
        },
        handerShopQtyChange(currentValue){
            let defaultQty = this.shopLabels[this.currVipKey];
            let key = this.currVipKey;
            let currShopQty = 0;
            if(key == 'vip1'){
                currShopQty = currentValue*10;
            }else if(key == 'vip2'){
                currShopQty = currentValue*10;
            }else if(key == 'vip5'){
                currShopQty = currentValue*10;
            }else if(key == 'vip3' || key == 'vip6' || key == 'vip9'){
                currShopQty = (currentValue-2)*10+20;
            }
            if(currShopQty < defaultQty){
                currShopQty = defaultQty;
            }
            let qty = currShopQty-defaultQty;
            this.amount = (this.price/100.0+(qty*3)).toFixed(2);
            this.shopQtyTxt = "可绑店铺数："+currShopQty+"家";
            this.shopQty = currShopQty;
        },
        handerShopQtyBlur(){
            let defaultQty = this.shopLabels[this.currVipKey];
            if(this.shopQty < defaultQty){
                this.shopQty = defaultQty;
            }
            let qty = this.shopQty-defaultQty;
            this.amount = (this.price/100.0+(qty*3)).toFixed(2);
            this.shopQtyTxt = "可绑店铺数："+this.shopQty+"家";
        },
        prepareBuy(){
            if(!this.currVip){
                return; 
            }
            //检查是否有待支付订单，如果有则刷新支付结果，如果没有则直接执行下一步
            let _this = this;
            this.$axios.get(this.$kit.api.vip.checkOrderStatus)
                .then((result)=>{
                    if(result.status){
                        let order = result.data;
                        console.log(result)
                        if(order.status != 1 && order.status != 2){
                            _this.$confirm(_this.msg("您有一笔待支付的订单，是否取消支付？"), _this.msg('支付提醒'), {
                                confirmButtonText: '取消支付',
                                cancelButtonText: '返回',
                                type: 'warning'
                            }).then(() => {
                                _this.cancelOrder(order.id);
                            });
                        }else{
                            _this.$confirm(_this.msg("您的订单已付款，请重新登录后确认？"), _this.msg('支付提醒'), {
                                confirmButtonText: '继续购买',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then(() => {
                                _this.doCheckBuy();
                            });
                        }
                    }else{
                        _this.doCheckBuy();
                    }
                });

        },
        cancelOrder(orderId){
            let _this = this;
            this.$axios.get(this.$kit.api.vip.cancelOrder+"?id="+orderId+"&status=5")
                .then((result)=>{
                    if(result.status){
                        _this.doCheckBuy();
                    }else{
                        _this.$message(result.msg);
                    }
                });
        },
        doCheckBuy(){
            let _this = this;
            this.$axios.get(this.$kit.api.vip.checkBuy+"?id="+this.currVip.id+"&t="+this.currVip.vip+"&shopQty="+this.shopQty+"&buyShop="+this.buyShop+"&s="+(this.saleIds.length>0?this.saleIds[0]: ''))
                .then((result)=>{
                    if(result.status){
                        _this.$confirm(result.data.msg+"， 您将要支付 <<"+result.data.amt+">>元差价", _this.msg('支付提醒'), {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then(() => {
                            _this.doBuy();
                        });
                    }else{
                        _this.$message(result.msg);
                    }
                });
        },
        doBuy(){
            window.open(this.$kit.api.vip.buy+"?id="+this.currVip.id+"&t="+this.currVip.vip+"&shopQty="+this.shopQty+"&buyShop="+this.buyShop+"&s="+(this.saleIds.length>0?this.saleIds[0]: ''));
            this.$confirm(this.msg('支付完成？点击确定查看支付结果'), this.msg('支付提醒'), {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$tabs.open("/admin/vip-order");
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.vip-wrap{
    width:100%; 
    height:90vh;
    min-height: 600px;
    background: #fffbf5;
    padding:15px;  box-sizing: border-box;
}

.table-wrap{
   box-sizing: border-box;
   min-width:100%;  overflow:auto;
}
.var-table{min-width:100%;}

.title-vip-type{ box-shadow: 0 0 20px rgba(100,100,100,0.4); width:200px;
   background-image: linear-gradient(to right, #FF0033 , #EE8D8D);
   font-size:30px;
   color: #FFCC33;
}

.title-group{background: white;  padding:15px 0px; 
   box-shadow: 0 0 20px rgba(100,100,100,0.4);
    color: #666;
}
.title-license th{background: white; padding:15px 0px; 
   box-shadow: 0 0 20px rgba(100,100,100,0.4); min-width:80px;
    color: #666;
}

.cell-vip{
    // padding:5px; 
    max-width:200px;}
.cell-vip > span{
   display:inline-block;
   padding: 30px 0px;
    width: 200px;max-width:200px; text-align:center;
   background:#fed77c;box-sizing: border-box;
   background-image: linear-gradient(to right, #FF0033 , #EE8D8D);
   color: #FFCC33; font-size:30px;
   box-shadow: 0px 0px 10px rgba(182,148,69, 0.5);
}

.cell-price{padding:5px; white-space:nowrap;}
.cell-price > span{display:inline-block; width:100%; 
   text-align:center;
   color: #333399; font-size:25px;padding: 30px 0px;
   cursor: pointer;
   border:1px solid rgba(51,51,153, 0.2);
   white-space:nowrap;
}

.cell-price > span:hover, .cell-price.active > span{
   background-image: linear-gradient(to right , #EE8D8D, #FF0033);
   color: #FFCC33;
   box-shadow: 0px 0px 10px rgba(255,0,51, 0.2) inset;
}
.vip-auth{
    margin-bottom:10px;
    padding:15px; 
    font-size:16px; color: #754e3b;
    border: 1.5px solid #ddd;

    >*{
        display:inline-block; margin-right:5px;
    }
}
</style>