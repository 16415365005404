<!-- 会员设置-->
<template>
    <div>
        <ctrl-box class="ctrl-box" :data="{}">
            <template>
                <div class="xgroup">
                    <el-link :underline="false" @click="prepareSave">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-bianji" font-size="27px;"/>
                            <span class="txt" >{{msg('保存编辑')}}</span>   
                        </div>
                    </el-link>
                    <el-link :underline="false" @click="getVipSetting">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-bianji1" font-size="27px;"/>
                            <span class="txt" >{{msg('获取会员设置')}}</span>   
                        </div>
                    </el-link>
                    <el-link :underline="false" @click="getPage">
                        <div class="wrap" >
                            <icon-svg icon-class="icon-bianji2" font-size="27px;"/>
                            <span class="txt" >{{msg('获取权限设置')}}</span>   
                        </div>
                    </el-link>
                </div>
            </template>
        </ctrl-box>
       
        <div class="vip-wrap">
            <h2>{{msg('会员类型设置')}}</h2>
            <el-table :data="types" border style="width: 100%" size="mini" highlight-current-row stripe>
                <el-table-column prop="vip" :label="msg('会员类型')" min-width="100"></el-table-column>
                <el-table-column prop="vipLabel" :label="msg('会员显示名')" min-width="100"></el-table-column>
                <el-table-column prop="remark1" :label="msg('会员备注1')"  min-width="100" show-overflow-tooltip></el-table-column>
                <el-table-column prop="remark2" :label="msg('会员备注2')"  min-width="100" show-overflow-tooltip></el-table-column>
                <el-table-column prop="remark3" :label="msg('会员备注3')"  min-width="100" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" min-width="60">
                    <template slot-scope="scope">
                        <el-link @click="editType(scope.row)"  style="margin-left:5px; margin-right:5px;" icon="el-icon-edit" type="primary"></el-link>
                    </template>
                </el-table-column>
            </el-table>
            <!--
            <div style="text-align:center; margin-top:5px;">
                <el-button  icon="el-icon-circle-plus-outline" type="primary" size="mini">添加更多</el-button>
            </div>
            -->
        </div>

        <div class="vip-wrap">
            <h2>{{msg('授权用户设置')}}</h2>
            <el-table :data="licenses" border style="width: 100%" size="mini" highlight-current-row stripe>
                <el-table-column prop="id" label="id" min-width="80"></el-table-column>
                <el-table-column :label="msg('用户使用数量')" min-width="120">
                    <template slot-scope="scope">
                        {{scope.row.min}}-{{scope.row.max}}
                    </template>
                </el-table-column>
                <el-table-column prop="remark1"  min-width="120" :label="msg('授权备注1')" show-overflow-tooltip></el-table-column>
                <el-table-column prop="remark2"  min-width="120" :label="msg('授权备注2')" show-overflow-tooltip></el-table-column>
                <el-table-column prop="remark3"  min-width="120" :label="msg('授权备注3')" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" min-width="100">
                    <template slot-scope="scope">
                        <el-link @click="handleMoveUp(licenses, scope.$index)" icon="el-icon-top"></el-link>
                        <el-link @click="handleMoveDown(licenses, scope.$index)" style="margin-left:5px;" icon="el-icon-bottom"></el-link>
                        <el-link @click="editLicense(scope.row)"  style="margin-left:5px; margin-right:5px;" icon="el-icon-edit" type="primary"></el-link>
                        <el-link @click="handleDelete(licenses, scope.$index)" icon="el-icon-delete" type="danger"></el-link>
                    </template>
                </el-table-column>
            </el-table>
            <div style="text-align:center; margin-top:5px;">
                <el-button  icon="el-icon-circle-plus-outline" @click="addLicense()" type="primary" size="mini">{{msg('添加更多')}}</el-button>
            </div>
        </div>

        <div class="vip-wrap">
            <h2>{{msg('会员价格设置')}}</h2>
            <el-table :data="prices" border style="width: 100%" size="mini" highlight-current-row stripe>
                <el-table-column :label="msg('会员类型')" min-width="100" prop="type">
                    <template slot-scope="scope">
                        {{scope.row.vip.vipLabel}}
                    </template>
                </el-table-column>
                <el-table-column :label="msg('授权用户')" min-width="100" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{scope.row.license.id}}: {{scope.row.license.remark1}}
                    </template>
                </el-table-column>
                <el-table-column :label="msg('会员价格')" min-width="80">
                    <template slot-scope="scope">
                        {{(scope.row.price/100).toFixed(2)}}
                    </template>
                </el-table-column>
                <el-table-column label="操作" min-width="100">
                    <template slot-scope="scope">
                        <el-link @click="handleMoveUp(prices, scope.$index)" icon="el-icon-top"></el-link>
                        <el-link @click="handleMoveDown(prices, scope.$index)" style="margin-left:5px;" icon="el-icon-bottom"></el-link>
                        <el-link @click="editPrice(scope.row)"  style="margin-left:5px; margin-right:5px;" icon="el-icon-edit" type="primary"></el-link>
                        <el-link @click="handleDelete(prices, scope.$index)" icon="el-icon-delete" type="danger"></el-link>
                    </template>
                </el-table-column>
            </el-table>
            <div style="text-align:center; margin-top:5px;">
                <el-button  icon="el-icon-circle-plus-outline" @click="addPrice()" type="primary" size="mini">{{msg('添加更多')}}</el-button>
            </div>
        </div>
        <div class="vip-wrap">
            <h2>{{msg('会员折扣设置')}}</h2>
            <el-table :data="sales" border style="width: 100%" size="mini" highlight-current-row stripe>
                <el-table-column prop="id" label="id" min-width="80"></el-table-column>
                <el-table-column prop="months" :label="msg('月份')" min-width="120"> </el-table-column>
                <el-table-column prop="sale" :label="msg('折扣%')" min-width="120">
                    <template slot-scope="scope">
                        {{scope.row.sale}}%
                    </template>
                </el-table-column>
                <el-table-column prop="remark"  min-width="120" :label="msg('折扣说明')" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" min-width="100">
                    <template slot-scope="scope">
                        <el-link @click="handleMoveUp(sales, scope.$index)" icon="el-icon-top"></el-link>
                        <el-link @click="handleMoveDown(sales, scope.$index)" style="margin-left:5px;" icon="el-icon-bottom"></el-link>
                        <el-link @click="editSale(scope.row)"  style="margin-left:5px; margin-right:5px;" icon="el-icon-edit" type="primary"></el-link>
                        <el-link @click="handleDelete(sales, scope.$index)" icon="el-icon-delete" type="danger"></el-link>
                    </template>
                </el-table-column>
            </el-table>
            <div style="text-align:center; margin-top:5px;">
                <el-button  icon="el-icon-circle-plus-outline" @click="addSale()" type="primary" size="mini">{{msg('添加更多')}}</el-button>
            </div>
        </div>

        <!--编辑vip-->
        <el-dialog v-if="currType" :title="msg('编辑会员类型')" :visible.sync="typeVisible" :width="isMinScreen?'100%':'60%'">
            <div>
                <div class="el-form-item">
                    <label class="el-form-item__label" style="width: 80px;">{{msg('会员类型')}}</label>
                    <div class="el-form-item__content" style="margin-left: 80px;">
                        {{currType.vipLabel}}({{currType.vip}})
                    </div>
                </div>
                <div class="el-form-item">
                    <label class="el-form-item__label" style="width: 80px;">{{msg('会员备注1')}}</label>
                    <div class="el-form-item__content" style="margin-left: 80px;">
                        <el-input type="textarea" size="mini" v-model="currType.remark1"></el-input>
                    </div>
                </div>
                <div class="el-form-item">
                    <label class="el-form-item__label" style="width: 80px;">{{msg('会员备注2')}}</label>
                    <div class="el-form-item__content" style="margin-left: 80px;">
                        <el-input type="textarea" size="mini" v-model="currType.remark2"></el-input>
                    </div>
                </div>
                <div class="el-form-item">
                    <label class="el-form-item__label" style="width: 80px;">{{msg('会员备注3')}}</label>
                    <div class="el-form-item__content" style="margin-left: 80px;">
                        <el-input type="textarea" size="mini" v-model="currType.remark3"></el-input>
                    </div>
                </div>
                <div style="text-align:center; margin-top:10px; magin-bottom:10px;">
                    <el-button type="primary" @click="typeVisible=false" size="mini">{{msg('确定')}}</el-button>
                </div>
            </div>
        </el-dialog>

        <!--编辑授权-->
        <el-dialog v-if="currLisence" :title="msg('编辑会员授权')" :visible.sync="lisenceVisible" :width="isMinScreen?'100%':'60%'">
            <div>
                <div class="el-form-item">
                    <label class="el-form-item__label" style="width: 120px;">{{msg('用户使用数量')}}</label>
                    <div class="el-form-item__content" style="margin-left: 120px;">
                        <el-input-number v-model="currLisence.min" :min="1" :max="99999" size="mini" :label="msg('最小用户数')"></el-input-number>
                        -
                        <el-input-number v-model="currLisence.max" :min="1" :max="99999" size="mini" :label="msg('最大用户数')"></el-input-number>
                    </div>
                </div>
                <div class="el-form-item">
                    <label class="el-form-item__label" style="width: 80px;">{{msg('授权备注1')}}</label>
                    <div class="el-form-item__content" style="margin-left: 80px;">
                        <el-input v-model="currLisence.remark1" ></el-input>
                    </div>
                </div>
                <div class="el-form-item">
                    <label class="el-form-item__label" style="width: 80px;">{{msg('授权备注2')}}</label>
                    <div class="el-form-item__content" style="margin-left: 80px;">
                        <el-input type="textarea" size="mini" v-model="currLisence.remark2"></el-input>
                    </div>
                </div>
                <div class="el-form-item">
                    <label class="el-form-item__label" style="width: 80px;">{{msg('授权备注3')}}</label>
                    <div class="el-form-item__content" style="margin-left: 80px;">
                        <el-input type="textarea" size="mini" v-model="currLisence.remark3"></el-input>
                    </div>
                </div>
                <div style="text-align:center; margin-top:10px; magin-bottom:10px;">
                    <el-button type="primary" @click="enterEditLicense()" size="mini">{{msg('确定')}}</el-button>
                </div>
            </div>
        </el-dialog>
        
        <!--编辑会员价格-->
        <el-dialog v-if="currPrice" :title="msg('编辑会员价格')" :visible.sync="priceVisible" :width="isMinScreen?'100%':'60%'"
            @closed="closeEditPrice"
        >
            <div>
                <div class="el-form-item">
                    <label class="el-form-item__label" style="width: 80px;">{{msg('会员类型')}}</label>
                    <div class="el-form-item__content" style="margin-left: 80px;">
                        <el-select v-model="currPrice.vipType" placeholder="请选择" size="mini" @change="selectVipType">
                            <el-option v-for="item in types" :key="item.vip" :label="item.vipLabel" :value="item.vip"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="el-form-item">
                    <label class="el-form-item__label" style="width: 80px;">{{msg('授权类型')}}</label>
                    <div class="el-form-item__content" style="margin-left: 80px;">
                        <el-select v-model="currPrice.licenseId" placeholder="请选择" size="mini" @change="selectLicense">
                            <el-option v-for="item in licenses" :key="item.id" :label="item.remark1" :value="item.id"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="el-form-item">
                    <label class="el-form-item__label" style="width: 80px;">{{msg('会员价格')}}</label>
                    <div class="el-form-item__content" style="margin-left: 80px;">
                        <el-input type="number" size="mini" v-model="currPrice.price" ></el-input>
                    </div>
                </div>
                <div style="text-align:center; margin-top:10px; magin-bottom:10px;">
                    <el-button type="primary" @click="enterEditPrice()" size="mini">{{msg('确定')}}</el-button>
                </div>
            </div>
        </el-dialog>


        <!--编辑折扣-->
        <el-dialog v-if="currSale" :title="msg('编辑会员折扣')" :visible.sync="saleVisible" :width="isMinScreen?'100%':'60%'"
        >
            <div>
                <div class="el-form-item">
                    <label class="el-form-item__label" style="width: 80px;">{{msg('月份')}}</label>
                    <div class="el-form-item__content" style="margin-left: 80px;">
                        <el-input-number v-model="currSale.months" :min="1" :max="100" size="mini"></el-input-number>
                    </div>
                </div>
                <div class="el-form-item">
                    <label class="el-form-item__label" style="width: 120px;">{{msg('折扣%')}}</label>
                    <div class="el-form-item__content" style="margin-left: 120px;">
                        <el-input-number v-model="currSale.sale" :min="1" :max="100" size="mini" :label="msg('百分数')"></el-input-number>
                    </div>
                </div>
                <div class="el-form-item">
                    <label class="el-form-item__label" style="width: 80px;">{{msg('折扣说明')}}</label>
                    <div class="el-form-item__content" style="margin-left: 80px;">
                        <el-input v-model="currSale.remark" ></el-input>
                    </div>
                </div>
                <div style="text-align:center; margin-top:10px; magin-bottom:10px;">
                    <el-button type="primary" @click="enterEditSale()" size="mini">{{msg('确定')}}</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
/* eslint-disable */　
import CtrlBox from "../../components/CtrlBox.vue";
export default {
    name: 'VipSetting',
    components: {
        CtrlBox: CtrlBox,
    },
    mounted(){
        this.getVips();
    },
    data(){
        return {
            prices: [],
            types: [],
            licenses: [],
            sales: [],
           
            typeVisible: false,
            currType: null,

            lisenceVisible:false,
            isAddLisence: false,
            currLisence: null,

            priceVisible: false,
            isAddPrice:false,
            currPrice: null,

            saleVisible: false,
            isAddSale: false,
            currSale:null,
        }
    },
    computed: {
        isMinScreen: {  //是否小屏幕，浏览器
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
        docHeight: {  //表格的高度
            get() {
                var height =  this.$store.state.docHeight;
                return height - 220;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods:{
        getVips(refresh){ 
            let url = this.$kit.api.server+"/am/vip/setting";
            this.$axios.get(url)
                .then((result) => {
                    if(result.status){
                        let data = result.data;
                        this.types = data.type;
                        this.licenses = data.license;
                        this.sales = data.sale;
                        let ps = data.price;
                        this.prices = [];
                        ps.forEach(p => {
                            let price = {};
                            price["price"] = p.price;
                            price['licenseId'] = p.licenseId;
                            price['vipType'] = p.vipType;
                            price["vip"] = this.types.find(t => t.vip == p.vipType);
                            price["license"] = this.licenses.find(t => t.id == p.licenseId);
                            this.prices.push(price);
                        });
                    }
                });
        },
        handleMoveUp(datas, index){
            if(index == 0){
                return;
            }
            let row = datas[index];
            let prevRow = datas[index-1];
            // datas[index-1] = row;
            // datas[index] = prevRow;
            //触发数组变动
            datas.splice(index, 1, prevRow);
            datas.splice(index-1, 1, row);
        },
        handleMoveDown(datas, index){
            if(index >= datas.length-1){
                return;
            }
            let row = datas[index];
            let lastRow = datas[index+1];
            // datas[index+1] = row;
            // datas[index] = lastRow;
            datas.splice(index, 1, lastRow);
            datas.splice(index+1, 1, row);
        },
        handleDelete(datas, index){
            this.$confirm(this.msg('确定要删除吗？'), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                if(index!=-1 && index<datas.length){
                    datas.splice(index,1);
                }
            });
        },
        editType(row){
            this.currType = row;
            this.typeVisible = true; 
        },
        editLicense(row){
            this.isAddLisence = false;
            this.currLisence = row;
            this.lisenceVisible= true;
        },
        addLicense(){
            let _this = this;
            this.currLisence = {
                id: _this.max(_this.licenses, "id")+1,
                min: 0,
                max: 0,
                remark1: null,
                remark2: null, 
                remark3: null,
            }
 
            this.isAddLisence = true;
            this.lisenceVisible= true;
        },
        enterEditLicense(){
            if(!this.currLisence.min){
                this.$message(this.msg("最小值不能为空"));
                return;
            }
            if(!this.currLisence.max){
                this.$message(this.msg("最大值不能为空"));
                return;
            }
            if(!this.currLisence.remark1){
                this.$message(this.msg("授权备注1不能为空"));
                return;
            }

            if(this.isAddLisence){
                this.licenses.push(this.currLisence);
                this.isAddLisence = false;
            }

            this.lisenceVisible = false;
        },
        editPrice(row){
            row.price = (row.price/100).toFixed(2);
            this.currPrice = row;
            this.priceVisible= true;
            this.isAddPrice = false;
        },
        addPrice(){
            this.currPrice = {
                licenseId: null,
                price: 0,
                vipType: null,
                vip: null,
                license: null,
            }
            this.isAddPrice = true;
            this.priceVisible = true;
        },
        enterEditPrice(){
            if(!this.currPrice.licenseId){
                this.$message(this.msg("请选择授权数量"));
                return;
            }
            if(!this.currPrice.vipType){
                this.$message(this.msg("请选择会员类型"));
                return;
            }
            if(!this.currPrice.price){
                this.$message(this.msg("请输入会员价格"));
                return;
            }

            if(this.isAddPrice){
                this.prices.push(this.currPrice);
                this.isAddPrice = false;
            }
            this.priceVisible = false;
        },
        editSale(row){
            this.currSale = row;
            this.saleVisible= true;
            this.isAddSale = false;
        },
        addSale(){
            this.currSale = {
                id: this.max(this.sales, "id")+1,
                months: 0,
                sale: 0,
                remark: null,
            }
            this.isAddSale = true;
            this.saleVisible = true;
        },
        enterEditSale(){
            if(!this.currSale.months){
                this.$message(this.msg("请输入月份"));
                return;
            }
            if(!this.currSale.sale){
                this.$message(this.msg("请输入折扣"));
                return;
            }
            if(!this.currSale.remark){
                this.$message(this.msg("请输入说明"));
                return;
            }
            if(this.isAddSale){
                this.sales.push(this.currSale);
                this.isAddSale = false;
            }
            this.saleVisible = false;
        },
        //选中vip
        selectVipType(val){
            let vip = this.types.find(t => t.vip == val);
            this.$set(this.currPrice,'vip', vip)
        },
        selectLicense(val){
            let lic = this.licenses.find(t => t.id == val);
            this.$set(this.currPrice,'license', lic)
        },
        closeEditPrice(){
            if(this.currPrice.price){
                let p = this.currPrice.price ;
                this.currPrice.price = (p*100).toFixed(0);
            }
            //如果是新增
        },
        prepareSave(){
            let _this = this;
            this.$confirm(this.msg('确定保存？'), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
                _this.doSave();
            });
        },
        getPage(){
            window.open(this.$kit.api.vip.jsonSetting,"_blank")
            // this.$axios.get(this.$kit.api.vip.jsonSetting)
            //     .then((result) => {
            //         // this.$message(result.msg)
            //         console.log(result);
            //     });
        },
        getVipSetting(){
            window.open(this.$kit.api.vip.setting,"_blank")
        },
        doSave(){
            // console.log(this.vips);
            let datas = {};
            datas.license = this.licenses;
            datas.type = this.types;
            datas.sale = this.sales;

            let ps = [];
            this.prices.forEach(e => {
                ps.push({
                    licenseId: e.licenseId,
                    vipType: e.vipType,
                    price: e.price
                });
            });
            datas.price = ps;

            let url = this.$kit.api.server+"/am/vip/save";
            this.$axios.post(url, datas)
                .then((result) => {
                    this.$message(result.msg)
                });
        },

        max(arr, key){
            let vals = arr.filter(a => a[key]).map(a => a[key]);
            return Math.max.apply(Math, vals);
        }

    }
    
}
</script>

<style scoped lang="scss">
.vip-wrap{padding:15px;
    h2{font-size:18px; margin:10px; padding:0px;}
}
</style>
